import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

const Reset = () => {

    const params = useParams();
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');

    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    const resetPassword = async (e) => {
        e.preventDefault();
        if(password === confirmation) {
            try{
                const request = await axiosInstance.post(`/v1/users/reset-password/${params.resetToken}`,{
                    newPassword : password
                })
                toast.success('Password Updated!');
                navigate('/signin')
            }
            catch(error){
                toast.error('An unexpected error occured.')
            }
        }else{
            toast.error('The passwords that you entered do not match.')
        }
    }

    return (
        <div className='registration-container'>
            <div className='register-form'>
                {/* <img alt='logo' src={ require('../../../usage/assets/logos/hpz_full_blue.svg').default } /> */}
                <h2>Reset Password</h2>
                <p>Hello there! Change your password <br /> to spring back to action.</p>
                <span></span>
                <div className='register-fields'>
                    <input type="password" placeholder="Password" id="password" value={password} onChange={event => setPassword(event.target.value)}/>
                    <input type="password" placeholder="Re-Type Password" id="password" value={confirmation} onChange={event => setConfirmation(event.target.value)}/>
                </div>
                <div className='register-actions'>
                    <form onSubmit={(e) => resetPassword(e)}><button className='reg-button-active'>Reset Password</button></form>
                    <p>Here by Mistake?</p>
                    <button onClick={() => window.location.pathname = '/register'} className='register-login-redirect'>Back to Login</button>
                </div>
            </div>
            <p className='registration-creds'>© 2022 CST NETWORK SECURITY SERVICES, LTD. • Terms of Service</p>
        </div>
    )
}

export default Reset