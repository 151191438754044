import React from 'react';
import './Contact.css';
import Earth from './Earth';
import StarsCanvas from './Stars';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from "axios";

const Contact = () => {

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });    

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // const handleData = (e) => {
    //     toast.success("We'll be in touch with you soon!")
    //     console.log(e)
    // }

    const contactRequest = async (e) => {
        try{
            const url = '/v1/contactus/create';
            const { data } = await axiosInstance.post(
                url,
                {
                    firstName: e.firstName, 
                    lastName: e.lastName, //NOT REQUIRED
                    company: e.text, //NOT REQUIRED
                    email: e.email,
                    message: '' //NOT REQUIRED
                },
                {
                    headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.co.uk" : "*")}
                }
            );
            toast.success("We'll be in touch with you soon!")
        }
        catch(error){
			console.log(error)
        }
    }

    const emailPattern =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return (
        <div className='contact-container'>
            <StarsCanvas />
            <div className='contact-form'>
                <form onSubmit={handleSubmit((data) => contactRequest(data))}>
                    <div className='contact-form-header'>
                        <h1 className='gradient-text'>Let’s work together</h1>
                        <p className='sub-text'>We're eager to understand the needs of your company. Drop us a message using the form below, and one of our founders will promptly reach out to you</p>
                        {/* {errors.lastName && <p>Last name is required.</p>} */}
                        {/* {errors.age && <p>Please enter number for age.</p>} */}
                    </div>
                    <div className='contact-form-name'>
                        <div>
                            <label className='small-text'>First Name</label>
                            <input {...register('firstName', { required: true })} placeholder='John' />
                        </div>
                        <div>
                            <label className='small-text'>Last Name</label>
                            <input {...register('lastName')} placeholder='Doe' />
                        </div>
                    </div>
                    <div className='contact-form-single'>
                        <label className='small-text'>Company</label>
                        <input {...register('text')} placeholder='Example Entity' />
                    </div>
                    <div className='contact-form-single'>
                        <label className='small-text'>Email</label>
                        <input {...register('email', { pattern: emailPattern, required: true })} placeholder='johndoe@example.com' />
                    </div>
                    <input type="submit" className='gradient-button' />
                    {errors.firstName ? <p className='error-subtext'>*First name is required.</p> : (errors.email ? <p className='error-subtext'>*Please enter a valid email.</p> : null)}
                </form>
            </div>
            <div className='contact-model'>
                <Earth />
            </div>
        </div>
    )
}

export default Contact