import React from 'react'
import './LandingRouter.css'
import {
  Informative,
  Landing,
  Pricing,
  Navbar,
  Description,
  Contact,
  Footer
} from '../../../Importer.js'

const LandingRouter = (props) => {

  window.addEventListener('mousemove', (event) => {blobFollow(event)})
  // const blob = document.getElementById('blob')

  const blobFollow = (event) =>{
    const { clientX, clientY } = event;
    
    // blobRef.style.left = `${clientX}px`;
    // blobRef.style.top = `${clientY}px`;
    //blobRef.current.offsetLeft = `${clientX}px`;
    // blobRef.current.offsetTop = `${mousePos.y}px`;

    const blob = document.getElementById('blob')

    try{
      blob.animate({
        left: `${clientX}px`,
        top: `${clientY}px`,
      }, {duration: 3000, fill: "forwards"})
    }catch(error){
      console.log(error)
    }
  }

  return (
    <div className='elements-routed'>
      <div id='blob'></div>
      <div id='blur'></div>
      <Navbar scheme={'light'} user={props.user}/>
      <Landing />
      <Informative />
      <Description />
      <Pricing />
      <Contact />
      <Footer />
    </div>
  )
}

export default LandingRouter