import React, { useState, useEffect } from 'react'
import './Cards.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Cards = () => {

    const [plans, setPlans] = useState([]);
    const navigate = useNavigate();
    const firebaseEASM = 'https://firebasestorage.googleapis.com/v0/b/hackplaza-40bf8.appspot.com/o/Sample-Reports%2FSurface%20Management.pdf?alt=media&token=3e7f7994-00af-4812-8a41-89890d153f4e'

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    useEffect(()=>{
        fetchPlans();
    }, [])

    const fetchPlans = async () => {
        try{
            const url = '/v1/plans';
            const res = await axiosInstance.get(url);
            setPlans(res.data);
        }catch(err) {
            // removed log(err);
        }
    }

  return (
    <div className='plans-container'>
        <h1 className='plans-header'>Plans & Pricing</h1>
        <h3>Chose between a selection of bite-sized, pre-configured plans for the most requested type of pentest<br />or scale directly through an agent.</h3>
        <div className='plans-cards'>
            {
                plans.map((plan) => (
                    <div className={'plans-plan plan-'+plan._id} key={plan._id}>
                        <h2>{plan.name}</h2>
                        <div>
                            <h1>{plan._id === '63615ab136d8763ae46acd5b' || plan._id === '635f9f63e3643a9bd03e0a6d' ? '£'+plan.price+'+' : '£'+plan.price}</h1>
                            <p>{plan.description}</p>
                            <ul>
                                {
                                    plan.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className='plans-buttons'>
                            <button className={plan._id === '635f9f2de3643a9bd03e0a69' ? 'button-closeby' : ''} onClick={() => plan._id === '63615ab136d8763ae46acd5b' || plan._id === '635f9f63e3643a9bd03e0a6d' ? window.open('mailto:info@cybersanctus.com') : navigate(`/payment/${plan._id}`)}>{plan._id !== '63615ab136d8763ae46acd5b' && plan._id != '635f9f63e3643a9bd03e0a6d' ? 'Choose Plan' : 'Contact Us'}</button>
                            {plan._id === '635f9f2de3643a9bd03e0a69' ? <button className='download-button' onClick={() => window.open(firebaseEASM)}><img src={require('../../usage/assets/icons/download.svg').default} /></button> : null}
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Cards