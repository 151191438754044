import './CheckoutForm.css'
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useRef, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import CardInput from './CardInput';

export default function CheckoutForm({planId}) {
  const [cookies] = useCookies(["cookie-name"]);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [document, setDocument] = useState(null);
  const [nda, setNDA] = useState(null);
  const ndaRef = useRef(null);
  const docRef = useRef(null);

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    console.log("The CheckoutForm has been invoked, planID is: ", planId)
  }, [])


  useEffect(() => {
    if(nda && nda.size > 500000){
      toast.warn("File size must be less than 500KB, upload a new file again");
      setNDA(null);
    }
    if(document && document.size > 500000){
      toast.warn("File size must be less than 50KB, upload a new file again");
      setDocument(null);
    }
  },[nda, document])

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("SUBMITTED!");
    console.log("E VALUE: ", e)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    console.log("[+] CREATING PAYMENT METHOD...")

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      // billing_details: {
      //   email: 'test@cybersanctus.com',
      // },
    }).then(async(result) => {
      console.log("[+] PAYMENT METHOD CREATED: ", result)

      const resPayments = axiosInstance.post(`/v1/payments/${planId}`, {paymentMethodId:result.paymentMethod.id}, {
        headers: {
          authorization: `Bearer ${cookies.jwt}`,
          "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")
        }
      })
      .then(async (result) => {
        console.log("API CALL TO PAYMENTS ", result)
        var { clientSecret } = result.data;
        setClientSecret(clientSecret);
        if (result.error) {
          console.log("[-] AN ERROR HAS OCCURRED: ", result.error.message);
        } else {
            console.log("No error with createPaymentMethod! Your cookies are: ", cookies.jwt)
    
            const link = `/v1/payments/save-payment/${planId}`;
            const res = await axiosInstance.post(link,
              {
                
                
              },{headers: {
                authorization: `Bearer ${cookies.jwt}`,
                "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")
              }});
    
            const {client_secret, status} = res.data;
    
            console.log("POST RESULT: ", res)
    
            if (status === 'requires_action') {
              stripe.confirmCardPayment(clientSecret).then(function(result) {
                if (result.error) {
                  setMessage("An unexpected error occured.");
                  console.log('There was an issue!');
                  console.log(result.error);
                  // Display error message in your UI.
                  // The card was declined (i.e. insufficient funds, card has expired, etc)
                } else {
                  setMessage("Payment status: " + status);
                  toast.success("Payment successful");
                  // Show a success message to your customer
                }
              });
            } else {
              setMessage("Payment status: " + status);
              // No additional information was needed
              // Show a success message to your customer
            }
            console.log(status);
            setIsProcessing(false);
        }
        // setLoading(false)
      });

      console.log("API CALL TO PAYMENTS ", resPayments)
      
    });


    

  };

  return (
    // <form id="payment-form" onSubmit={handleSubmit}>
    //   <PaymentElement id="payment-element" />
    //   <input type="file" style={{display: 'none'}} ref={docRef} accept=".pdf" onChange={(e) => setDocument(e.target.files[0])}/>
    //   <input type="file" style={{display: 'none'}} ref={ndaRef} accept=".pdf" onChange={(e) => setNDA(e.target.files[0])}/>
    //   <button className="pay-btn" onClick={(e) => docRef.current.click() + e.preventDefault()} id="submit">Pentesting Permission<img onClick={() => window.location.pathname = '/documentation'} src={require('../assets/icons/info.svg').default} id="info"/></button>
    //   <button className="pay-btn" onClick={(e) => ndaRef.current.click() + e.preventDefault()} id="submit">Letter of Engagement<img onClick={() => window.location.pathname = '/documentation'} src={require('../assets/icons/info.svg').default} id="info"/></button>
    //   <button className="pay-btn" disabled={isProcessing || !stripe || !elements} id="submit">
    //     <span id="button-text">
    //       {isProcessing ? "Processing ... " : "Pay now"}
    //     </span>
    //   </button>
    //   {/* Show any error or success messages */}
    //   {message && <div id="payment-message">{message}</div>}
    // </form>

    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <PaymentElement id="payment-element" /> */}
      {/* <PaymentElement /> */}
      {/* <CardInput /> */}
      <CardElement />
      <button className="pay-btn" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

    // <div>
    //   <div>
    //     <CardInput />
    //     <div>
    //       {/* <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitPay}>
    //         Pay
    //       </Button> */}
    //       <button variant="contained" color="primary" onClick={handleSubmit}>
    //         Subscription
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
}


/**
 * THIS IS A SEPARATIOR BETWEEN THE PREVIOUS CODE AND THE NEWLEY IMPORTED CODE
 * DIRECTLY FROM THE STRIPE DOCUMENTATION TO SEE IF IT WOULD CHANGE ANYTHING.
 */