import React from 'react'
import { Outlet } from 'react-router-dom'
// import './Register.css'

const Email = () => {
  return (
    <>
      <div className='email-verification' style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center', height: '100vh', justifyContent: 'center'}}>
          <img src={require("../../usage/assets/logos/cst_full_white.png")} style={{width: '40vw', minWidth: '20em'}} />
          <h1 style={{marginTop: '2em', color: 'white', textAlign: 'center'}}>An email has been sent to your inbox with a verification link.</h1>
          <Outlet/>
      </div>
    </>
  )
}

export default Email