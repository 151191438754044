import React, { useEffect, useRef, useState } from 'react'
import './landing.css'
import { toast } from 'react-toastify';
import Typewriter from 'typewriter-effect';
import axios from "axios";

const Landing = () => {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });    

  const textRef = useRef(null);
  const letters = "abcdefghijklmnopqrstuvwxyz";
  let interval = null;
  const [waitlistEmail, setWaitlistEmail] = useState('')

  const matrixEffect = () => {
    let iteration = 0;
  
    clearInterval(interval);
    
    interval = setInterval(() => {
      textRef.current.innerText =  textRef.current.innerText
        .split("")
        .map((letter, index) => {
          if(index < iteration) {
            return textRef.current.dataset.value[index];
          }
        
          return letters[Math.floor(Math.random() * 26)]
        })
        .join("");
      
      if(iteration >= textRef.current.dataset.value.length){ 
        clearInterval(interval);
      }
      
      iteration += 1 / 3;
    }, 30);
  }

  const contactRequest = async () => {
    try{
        const url = '/v1/waitlist/create';
        const { data } = await axiosInstance.post(
            url,
            {
                email: waitlistEmail
            },
            {
                headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.co.uk" : "*")}
            }
        );
        toast.success("We'll be in touch with you soon!")
    }
    catch(error){
      toast.error("Something went wrong...")
    }
  }

  return (
    <div className='landing2-containter'>
      <div className='landing2-header'>
        <h1>Expert<br/>
          <span data-value="penetration tests" ref={textRef} onMouseEnter={matrixEffect}>penetration tests</span><br/>
          for businesses
        </h1>
        <img className='mobile-only' src={require('./chain.png')} style={{width: '80vw'}}/>
        <p>
          Satisfy <span>ISO 27001</span> compliance requirements and meet security evaluations from vendors for web, mobile apps, APIs, and external network infrastructure.
        </p>
      </div>
      <div className='landing2-cta'>
        <input type='text' placeholder='Enter your email' value={waitlistEmail} onChange={event => setWaitlistEmail(event.target.value)} />
        <button className='cta-flex' onClick={() => contactRequest()}>Join Waitlist</button>
      </div>
      <div className='landing2-footer'>
        <p>Trusted by some of the worlds most innovative companies</p>
        <ul>
            <li><img alt='elearn' src={ require('../../usage/assets/logos/ELS.svg').default } /></li>
            <li><img alt='offensive security' src={ require('../../usage/assets/logos/offsec.svg').default } /></li>
            <li><img alt='Hack The Box' style={{width:'150px'}} src={ require('../../usage/assets/logos/HTB.svg').default } /></li>
            <li><img alt='Certified' src={ require('../../usage/assets/logos/CEH.svg').default } /></li>
        </ul>
      </div>
    </div>
  )
}

export default Landing