import React, { useState, useEffect } from 'react'
import './BlogPage.css'
import { Footer } from "../../../Importer.js";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MarkDown from 'markdown-to-jsx';
import moment from 'moment'

const BlogPage = () => {

    const [blogData, setBlogData] = useState();
    const cstIcon = require('../../usage/assets/logos/cst_min_white.png')

    const params = useParams();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    }, []);

    useEffect((e) => {
        fetchSingleBlog(e);
    }, [])

    const fetchSingleBlog = async (e) => {
        try {
            const url = `/v1/blogs/${params.blogId}`;
            const blog = await axiosInstance.get(url, {});
            setBlogData(blog.data.data.blog)
            console.log(blogData)
        } catch (error) {
            console.log("error");
        }
    };

    return (
        <>
            {blogData ?
                (
                    <div className='blogpage-container'>
                        <div className='blogpage-header'>
                            <div className='blog-content-regular-info-tags'>
                                {
                                    blogData.tags.map((tag) => {
                                        return(
                                            <p>{tag}</p>
                                        )
                                    })
                                }
                            </div>
                            <h1>{blogData.title}</h1>
                            <p style={{ lineHeight: '1.4em', fontSize: 'large' }}>{blogData.description}</p>
                            <div className='blogpage-header-author'>
                                <img src={cstIcon} />
                                <div className='blogpage-header-author-info'>
                                    <p style={{ color: 'white', margin: '0.2em 0', fontSize: '16px' }}>CyberSanctus Team</p>
                                    <p style={{ margin: '0.2em 0', fontSize: '14px' }}>{moment.utc(blogData.createdAt).local().startOf('seconds').fromNow()}</p>
                                </div>
                            </div>
                        </div>
                        <div className='blogpage-content'>
                            <MarkDown>{blogData.body}</MarkDown>
                        </div>
                        <p style={{ color: 'grey' }}>Image Attribution: Image By <a href={blogData.sourceLink}>{blogData.source}</a></p>
                        <Footer />
                    </div>
                )

            : null}
        </>
    )
}

export default BlogPage