import './payment.css';
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../usage/CheckoutForm/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

//CSS properties to be used by react loader
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  width: "40px",
  height: "40px"
};

function Payment() {

  const params = useParams();
  const planId = params.planId;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  //color for the loader
  let [color, setColor] = useState("#ffffff");

  const [cookies] = useCookies(["cookie-name"]);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);

    useEffect(() =>{
        if (!cookies.jwt){
            toast.error('Please login or create account first to buy plan')
            navigate('/register')
        }
    }, [cookies, navigate])

  useEffect(() => {
    setLoading(true);
    axiosInstance.get("/v1/payments/config", {
      headers: {
        authorization: `Bearer ${cookies.jwt}`,
        "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")
      }
    }).then(r => {
      console.log("API CALL TO CONFIG ", r)
      const { publishableKey } = r.data
      setStripePromise(loadStripe(publishableKey));
      // setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
      console.log("SET STRIPE PROMISE TO: ", stripePromise);
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   const res = axiosInstance.post(`/v1/payments/${planId}`, {}, {
  //     headers: {
  //       authorization: `Bearer ${cookies.jwt}`,
  //       "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")
  //     }
  //   })
  //   .then(async (result) => {
  //     console.log("API CALL TO PAYMENTS ", result)
  //     var { clientSecret } = result.data;
  //     setClientSecret(clientSecret);
  //     setLoading(false)
  //   });

  //   console.log("API CALL TO PAYMENTS ", res)
  // }, []);

  // const inputStyle = {
  //   iconColor: '#c4f0ff',
  //   color: '#ffffff',
  //   fontWeight: '500',
  //   fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  //   fontSize: '16px',
  //   fontSmoothing: 'antialiased',
  //   ':-webkit-autofill': {
  //     color: '#ffffffff',
  //   },
  //   '::placeholder': {
  //     color: '#ffffffff',
  //   },
  // }

  const appearance = {
    theme: 'night'
  };

  return (
    <div className='payment-container'>
      <img alt='logo' style={{width: '400px', maxWidth: '20em'}} src={require('../../usage/assets/logos/cst_full_white.png')} />
      {
        loading ? 
        <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      :(<>
          {/* {clientSecret && stripePromise && (
            // <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            //   <CheckoutForm planId={planId} />
            // </Elements>
          )} */}
          {(
            <Elements stripe={stripePromise} >
              <CheckoutForm planId={planId} />
            </Elements>
          )}
        </>)
      }
      
    </div>
  );
}

export default Payment;
