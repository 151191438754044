import { GoogleOAuthProvider } from '@react-oauth/google';
import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {
    Login,
    Pricing,
    Dashboard,
    Payment,
    LandingRouter,
    Verify,
    Forgot,
    EmailSent,
    Reset,
    Terms,
    Register,
    Blog,
    BlogPage,
    Documentation,
    Navbar,
    Footer
} from "./Importer.js";

function App(props) {
    const pathname = window.location.pathname;
    const [authValue, setAuthValue] = useState(true);
    const [userData, setUserData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPentester, setIsPentester] = useState(false);
    const [cookies] = useCookies(["cookie-name"]);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    useEffect((e) => {
        fetchUser(e);
    }, []);

    useEffect(
        (e) => {
            if (userData != null) {
                if (userData.data.user.role === "admin") {
                    setIsAdmin(true);
                    setIsPentester(false);
                }
                if (userData.data.user.role === "pentester") {
                    setIsPentester(true);
                    setIsAdmin(false);
                }
                if (userData.data.user.role !== "admin") {
                    setIsAdmin(false);
                }
            }
        },
        [userData]
    );

    const fetchUser = async (e) => {
        try {
            const url = "/v1/users/me";
            const user = await axiosInstance.get(url, {
                headers: { authorization: `Bearer ${cookies.jwt}` },
            });
            setUserData(user);
        } catch (error) {
            // removed log("error");
        }
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <div className="body">
                <TawkMessengerReact
                    propertyId="63a9cd9247425128790a26db"
                    widgetId="1gl7km1rg"/>
                <ToastContainer position="top-center" limit={1} autoClose={2000} />
                <Router pathname={pathname}>
                    <Routes>
                        <Route exact path="/" element={<LandingRouter user={userData} />} />
                        <Route path="/home" element={<LandingRouter user={userData} />} />

                        <Route path="/signin" element={<><Navbar scheme='light'/><Login /></>} />
                        <Route path="/register" element={<><Navbar scheme="light" /><Register /></>} />
                        <Route path="/register/email" element={<EmailSent />} />

                        <Route path="/verify/:verifyToken" element={<Verify />} />
                        <Route path="/forgot" element={<><Navbar scheme='light'/><Forgot /></>} />
                        <Route path="/reset-password/:resetToken" element={<><Navbar scheme="light" /><Reset /></>} />
                        
                        <Route path="/documentation" element={<><Navbar scheme="light"/><Documentation /></>} />
                        <Route path="/blog" element={<><Navbar scheme="light"/><Blog /></>} />
                        <Route path="/blog/:blogId" element={<><Navbar scheme="light"/><BlogPage /></>} />
                        <Route path="/plans" element={<><Navbar scheme="light" user={userData} /><Pricing /><Footer /></>}/>
                        <Route path="/payment/:planId" element={<><Navbar scheme="dark" user={userData} /><Payment authenticated={authValue} /></>}/>
                        <Route path="/terms-of-service" element={<><Navbar scheme="light" user={userData} /><Terms/></>}/>
                        {/* <Route path="/dashboard/terms" element={<TOS userData={userData} />}/> */}
                        <Route
                            path="/dashboard"
                            element={
                                <Dashboard userData={userData} routeTo={0}/>
                                // <>
                                //     {/* <Navbar scheme="dark" user={userData} />{" "} */}
                                //     {isAdmin ? (
                                //         <AdminPanel userData={userData} />
                                //     ) : isPentester ? (
                                //         <>
                                //             <Pentester userData={userData} />
                                //         </>
                                //     ) : (
                                //         <>
                                //             <Dashboard userData={userData} />
                                //             {/* <Dashbar /> */}
                                //         </>
                                //     )}
                                // </>
                            }
                        />
                        {/* <Route
                            path="/dashboard/settings"
                            element={
                                <>
                                    <Navbar scheme="dark" user={userData} />{" "}
                                    {isAdmin ? (
                                        <UsersArray userData={userData} />
                                    ) : (
                                        <>
                                            <ClientSettings userData={userData} />
                                            <Dashbar />
                                        </>
                                    )}
                                </>
                            }
                        /> */}
                        <Route path="/dashboard/services" element={<Dashboard userData={userData} routeTo={1}/>}/>
                        <Route path="*" element={<Navigate to="/" />}/>
                    </Routes>
                </Router>
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
