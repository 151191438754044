import React from 'react'
import './Informative.css'

const Informative = () => {
  return (
    <div className='informative-both'>
      <div className='informative-container'>
        <h1>Leading The Industry</h1>
        <span className='info-additional'>Powering The Decline in Cyber Attacks</span>
        <div className='informative-cards'>
          <div className='informative-damages'>
            <img src={require('./attacker.svg').default} />
            <div>
              <h1>$4M+</h1>
              <p>AVERAGE BREACH</p>
            </div>
          </div>
          {/* <div className='informative-divider'></div> */}
          <div className='informative-clients'>
          <img src={require('./scale.svg').default} />
            <div>
              <h1>$10T+</h1>
              <p>IN ANNUAL ATTACKS</p>
            </div>
          </div>
          {/* <div className='informative-divider'></div> */}
          <div className='informative-misc'>
          <img src={require('./attacker.svg').default} />
            <div>
              <h1>2200+</h1>
              <p>DAILY ATTACKS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Informative