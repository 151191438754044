import React from 'react'
import './Documentation.css'

const Documentation = () => {
  return (
    <div className='about-container'>
        <div className='about-content'>
            <div>
                <h1>What is CyberSanctus?</h1>
                <p>CyberSanctus is a penetration testing service that helps organizations identify and 
                    remediate vulnerabilities in their systems. Our service is designed to be customizable 
                    and scalable to meet the specific needs and wants of our customers. We understand 
                    that every organization has different security requirements, and we strive to provide 
                    a tailored solution for each one of our clients.<br /><br />
                    Our service includes different packages to cater to different types of customers. We 
                    offer a 'starter' package for small businesses and individual entrepreneurs who want 
                    to ensure the security of their systems but have a limited budget. The 'business' 
                    package is designed for medium-sized organizations that want a comprehensive 
                    security assessment of their systems. And the 'enterprise' package is for large 
                    organizations that require a detailed and in-depth analysis of their systems, 
                    networks, and applications. <br /><br />
                    At CyberSanctus, we understand the importance of security and the potential risks of 
                    having vulnerabilities in your systems. Our team of experts is dedicated to helping 
                    you safeguard your assets and sensitive data by identifying and remedying 
                    vulnerabilities in your systems. With our customizable and scalable service, you can 
                    trust that we will provide you with a solution that fits your specific needs and budget.</p>
            </div>
            <div>
                <h1>What is pentesting?</h1>
                <p>Penetration testing (pentesting) is a simulated cyber attack against a computer 
                    system, network or web application to evaluate the security of the system. The goal 
                    of a pentest is to identify vulnerabilities that an attacker could potentially exploit and 
                    to provide recommendations for how to fix those vulnerabilities. Pentests can be 
                    useful for organizations as it allows them to see their system's security through the 
                    eyes of an attacker and find and fix weaknesses before they can be exploited. <br /><br />
                    Companies and organizations buy pentests to ensure the security of their systems 
                    and to comply with industry regulations and standards. Banks, healthcare providers, 
                    government agencies, and other organizations that handle sensitive information 
                    often perform regular pentests to protect their assets and sensitive data.
                </p>
            </div>
            <div>
                <h1>What are the risks of having a vulnerable website?</h1>
                <p>Having a website with vulnerabilities can put an organization at risk in a number of ways.
                    <ul>
                        <li>Data breaches: If a website has vulnerabilities, an attacker may be able to gain 
                            unauthorized access to sensitive information, such as personal data, financial 
                            information, or confidential business information. This can lead to a data 
                            breach, which can have serious consequences for both the organization and 
                            its customers.
                        </li>
                        <li>Loss of reputation: A data breach or a security incident can damage an 
                            organization's reputation, potentially leading to a loss of customers, partners, 
                            and investors. It can also lead to legal action and fines.
                        </li>
                        <li>Downtime: Website vulnerabilities can also be exploited to launch a Denial of 
                            Service (DoS) attack, which can cause a website to become unavailable to 
                            legitimate users. This can result in lost revenue and damage to the 
                            organization's reputation.
                        </li>
                        <li>Compromised servers: If an attacker is able to exploit a vulnerability and gain 
                            access to a website's server, they may be able to install malware or use the 
                            server to launch further attacks.
                        </li>
                        <li>Unauthorized access: Attackers can also use vulnerabilities to gain 
                            unauthorized access to an organization's internal networks and systems, 
                            potentially giving them access to sensitive information and resources.
                        </li>
                        <li>Compliance: Many industries have regulations that require companies to 
                            ensure that their systems are secure. A website with vulnerabilities can put an 
                            organization at risk of non-compliance, which can lead to fines and penalties.
                            In summary, having a website with vulnerabilities can put an organization at risk of 
                            data breaches, loss of reputation, downtime, compromised servers, unauthorized 
                            access, and non-compliance with regulations. It is crucial for organizations to identify 
                            and fix vulnerabilities in their websites as soon as possible to protect their assets and 
                            sensitive data.
                        </li>
                    </ul>
                </p>
            </div>
            <div>
                <h1>Penetration testing procedure</h1>
                <p>The penetration testing procedure typically begins with the identification of the target systems
                    and networks. This can include a wide range of systems such as servers, workstations, and mobile
                    devices, as well as networks such as local area networks (LANs) and wide area networks (WANs).
                    Once the target systems and networks have been identified, the penetration tester will then
                    conduct research to gather information about the target. This can include researching the
                    organization's public website, network infrastructure, and employee information.<br /><br />
                    The next step in the penetration testing procedure is the actual testing phase. This phase
                    involves the use of various tools and techniques to attempt to gain access to the target 
                    systems and networks. This can include using automated tools to identify vulnerabilities,
                    such as network scanners, as well as manual testing, such as attempting to exploit known
                    vulnerabilities. The penetration tester will also use social engineering techniques, such
                    as phishing, in order to trick employees into giving away sensitive information.<br /><br />
                    Once the testing phase is complete, the penetration tester will then analyze the results and 
                    compile a report detailing the vulnerabilities that were identified and the methods that were 
                    used to exploit them. The report will also include recommendations for remediation, such as 
                    patching systems and software, implementing security controls, and increasing employee awareness 
                    training.<br /><br />After the report is delivered, the organization can then use the information
                    to take the necessary steps to remediate the vulnerabilities and improve their security posture. 
                    This can include applying software updates and patches, implementing security controls, and educating
                    employees about how to recognize and respond to potential cyber threats.<br /><br /> It's worth 
                    noting that Penetration testing should be conducted regularly, as new vulnerabilities are discovered
                    and new attack methods are developed. Organizations should also consider different types of penetration
                    testing, such as internal and external, to ensure they are covering all possible attack vectors.
                </p>
            </div>
            <div>
                <h1>How to structure a "Pentesting Permission" Document</h1>
                <p>
                    When structuring a "Permission to Pentest" document for our executives to approve, it is important to ensure that
                    it is legally compliant with UK regulations. Below are some key elements that should be included
                    in the document to ensure that our pentesters are able to legally perform a penetration test:<br /><br />
                    <ul>
                        <li>Scope of the pentest: Clearly define the scope of the pentest, including the specific systems and
                        networks that will be tested, the types of tests that will be performed (such as external, internal, or wireless),
                        and any specific goals or objectives of the test.</li>

                        <li>Duration of the pentest: Specify the start and end dates of the pentest
                        ,as well as the expected duration of the test.</li>

                        <li>Communication and coordination: Clearly outline the communication and
                        coordination process between the client and our pentesters, including how and when our pentesters will notify the
                        client of any vulnerabilities or issues that are discovered during the test.</li>
                        <li>
                        Confidentiality and non-disclosure:
                        Include a confidentiality and non-disclosure agreement that requires our pentesters to keep all information and
                        data related to the pentest confidential, and prohibits them from disclosing any information about the test to
                        any third parties. Please not that all pentesters have already pre-signed a fully-inclusive NDA and are already
                        required to abide by standard regulation, however if the client wished to overwrite this agreement, they may send
                        in additional paperwork through to the contact email, including an order number and receipt</li>

                        <li>Compliance with laws: Include a statement that our pentesters will conduct the pentest
                        in compliance with all applicable laws and regulations, including the UK Computer Misuse Act 1990 and the
                        General Data Protection Regulation (GDPR).</li>

                        <li>Post-test activities: Specify the activities that will be performed
                        after the test, such as providing a detailed report of findings and recommendations for remediation of vulnerabilities.</li>

                        <li>Obtaining of consent: Include a statement that the client has given permission for the test to be conducted and that
                        they understand the nature of the test and any potential risks.</li>
                    </ul>
                </p>
            </div>
            <div>
                <h1>How to structure a "Letter of Engagement" Document</h1>
                <p>
                    When structuring a "Letter of Engagement" document for our pentesters, it is important to clearly outline the scope,
                    responsibilities, and expectations of the engagement between the client (yourself) and our pentesters. Below are some key
                    elements that should be included in the document to ensure a clear understanding of the engagement:<br /><br />
                    <ul>
                        <li>A clear and concise scope for our pentesters to follow. This step should not be skipped as it will result in an instant
                            request rejection. Here is a shortened example of what a scope should look like:<br /><br />
                            Scope: [*.website.com, 10.10.10.*], Blacklisted Scope: [10.10.8.*]
                        </li>
                        <li>A clear description of the services that will be provided by the pentester, including the scope of the pentest, the
                            specific systems and networks that will be tested, the types of tests that will be performed, and any specific goals
                            or objectives of the test.</li>

                        <li>The timeline for the engagement, including the start and end date, expected duration, and any specific milestones
                            or deadlines that need to be met.</li>

                        <li>Communication plan, including some contact information that should be viewed by our pentesters, the frequency of communication
                            and the method of communication.</li>
                    </ul>
                </p>
            </div>
            <div>
                <h1>Emergency Response Team</h1>
                <p>CyberSanctus' ERT team is a group of experts who are trained and equipped to respond 
                    to cyber security incidents, such as data breaches, network intrusions, and other 
                    security-related incidents. Our ERT team is composed of security analysts, incident 
                    responders, forensic investigators and legal advisors who are experienced in handling 
                    a wide range of security incidents, and have the necessary tools and resources to 
                    quickly identify, contain, and resolve security incidents.<br /><br />
                    Our ERT team is designed to help organizations quickly and effectively respond to 
                    security incidents in order to minimize the impact to their systems, networks, and 
                    data. We understand that security incidents can happen at any time, and that quick 
                    action is crucial in order to contain and resolve the incident as soon as possible.
                    When an incident occurs, our ERT team is activated immediately and works to 
                    understand the scope and impact of the incident, identify the cause, and implement 
                    the necessary countermeasures and mitigation steps to limit the damage.<br /><br /> Our ERT 
                    team will work with you to recover your systems and networks, and ensure that the 
                    incident is fully resolved and that your organization's systems are secure once again.
                    Choosing CyberSanctus' ERT team will give you the peace of mind that comes with 
                    knowing that your organization has experts on hand to respond quickly and 
                    effectively to security incidents.<br /><br /> Our team is experienced, professional, and dedicated 
                    to helping you minimize the impact of security incidents, and to get your systems 
                    and networks back to normal operation as quickly as possible. With CyberSanctus' ERT 
                    team, you can trust that your organization's assets and sensitive data are in good 
                    hands.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Documentation