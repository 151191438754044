import React from 'react'
import './Description.css'

const description = () => {
  const docs1 = require('./hunter.svg').default;
  const docs2 = require('./hacker.svg').default;
  const docs3 = require('./computer.svg').default;
  return (
    <div className='description-both'>
      <div className='description-container'>
        <div className='description-1'>
          <img src={docs3} />
          <div>
            <h1>Satisfying Regulatory Standards</h1>
            <p>At CyberSanctus, we prioritize satisfying regulatory standards to ensure robust security controls for your systems. We strictly adhere to industry requirements such as SOC2, guaranteeing that our practices meet the highest standards for security, availability, processing integrity, confidentiality, and privacy. Working collaboratively in specialized teams, we excel in various areas such as vulnerability chaining, business logic assessment, and authentication and authorization testing.
            </p>
            <button onClick={() => window.open('mailto:info@cybersanctus.com')}>Learn More</button>
          </div>
        </div>
        <div className='description-2'>
          <img src={docs2} />
          <div>
            <h1>Industry Leading Security Researchers</h1>
            <p>Our team of security researchers at CyberSanctus consists of industry-leading experts who hold certifications such as the OSCP, OSCE, CISSP, CREST, and CEH. Their extensive knowledge and proficiency enable them to handle complex security challenges with precision. We constantly stay updated on emerging threats and cutting-edge techniques, ensuring that our services remain at the forefront of the industry.
            </p>
            <button onClick={() => window.location.pathname = '/plans'}>Scale Now</button>
          </div>
        </div>
        <div className='description-3'>
          <img src={docs1} />
          <div>
            <h1>Your Pentest Report, On-Demand</h1>
            <p>At CyberSanctus, we understand the importance of painlessly fulfilling your partner or customers security requests. With our on-demand pentest report, you can easily meet these requirements. Our comprehensive reports are always available, allowing you to send up-to-date reports to your partners anytime. Gain the confidence of your stakeholders with our detailed and readily accessible security reports that demonstrate your commitment to security and compliance.
            </p>
            <button onClick={() => window.open('mailto:info@cybersanctus.com')}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default description