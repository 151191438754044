import React, { useState, useEffect } from 'react'
import './Infobar.css'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

const Infobar = (props) => {

  const searchIcon = require('./search.svg').default
  const bellIcon = require('./bell.svg').default
  const [userEmail, setUserEmail] = useState('client@testmail.com');
  const [shrunkEmail, setShrunkEmail] = useState(userEmail.substring(0, userEmail.indexOf("@")))
  const [actHidden, setActHidden] = useState(true);
  const [notiHidden, setNotiHidden] = useState(true);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState('');

  const navigate = useNavigate('')

  useEffect(() => {
    console.log(props)
    setUserData(props.userData.userData);
    try{
      setUserId(userData.data.user._id)
      establishSocket()
      setUserEmail(userData.data.user.email)
      setShrunkEmail(userEmail.substring(0, userEmail.indexOf("@")))
    }
    catch(err){

    }
    console.log(process.env.REACT_APP_SOCKET_URL)
  }, [props])

  const establishSocket = () => {
    console.log(`${process.env.REACT_APP_SOCKET_URL}?user=${userId}`)
    const socketURL = `${process.env.REACT_APP_SOCKET_URL}?user=${userId}`
    console.log(socketURL)
    const socket = io(socketURL, { transports: ['websocket'] });

    // Socket event listeners
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('onNotification', (data) => {
      console.log('Received message:', data);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }

  const manageActions = () =>{
    if(notiHidden != true){
      setNotiHidden(true);
      setActHidden(!actHidden);
    }
    else{
      setActHidden(!actHidden);
    }
  }

  const manageNotifications = () =>{
    if(actHidden != true){
      setActHidden(true);
      setNotiHidden(!notiHidden);
    }
    else{
      setNotiHidden(!notiHidden);
    }
  }

  return (
    <div className='infobar-container'>
        <div className='infobar-page'>
            <p><span>Pages</span> / <span>{props.path}</span></p>
            <h1 onClick={() => window.location.reload()}>{props.path}</h1>
        </div>
        <div className='infobar-actions'>
            <div className='infobar-input'>
                <img src={searchIcon} />
                <input type="text" placeholder="Search..." />
            </div>
            <img src={bellIcon} onClick={() => manageNotifications()}/>
            <h4 className='infobar-avatar' onClick={() => manageActions()}>{userEmail[0].toUpperCase()}</h4>
        </div>
        <div className={ !actHidden ? 'infobar-details-popup' : 'hidden'}>
          <p className='details-popup-header'>👋 Hey, {shrunkEmail}</p>
          <span></span>
          <p onClick={() => navigate('/home')}>Home</p>
          <p>Settings</p>
          <p onClick={() => navigate('/terms-of-service')}>Terms</p>
          <p className='logout-button' onClick={() => {
            Cookies.remove('jwt');
            window.location.pathname = '/signin';
          }}>Log out</p>
        </div>
        <div className={ !notiHidden ? 'infobar-notis-popup' : 'hidden' }>
          <div className='notis-header'>
            <p className='infobar-heavy-text'>Notifications</p>
            <p className='notis-read'>Mark read</p>
          </div>
          <div className='notis-none'>
            <p className='small-text'>Nothing to see here... 👀</p>
          </div>
          {/* <div className='notis-noti'>
            <img src={bellIcon}/>
            <div>
              <p className='infobar-heavy-text'>Notification Title</p>
              <p>Notification content...</p>
            </div>
          </div> */}
        </div>
    </div>
  )
}

export default Infobar